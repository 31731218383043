const data = [
    {
        title: "Buy Me A Coffee",
        description: "Dishing Out Amazing Solutions & Gifts For Your Better Life. Often called a Renaissance Man by thought leaders, Greg Halpern is now delivering all of his Projects for a Better World, giving his most significant contributions to everyone, making people healthier, happier, and wealthier so that current and future generations will have a planet worth inheriting.",
        url: "https://buymeacoffee.com/greghalpern",
        imagePath: "/images/Slide1.png",
        tags: ["#Solutions", "#Gifts", "#Renaissance Man", "#Better World"],
        category: "Philanthropy"
    },
    {
        title: "Greg Halpern's Projects for a Better World",
        description: "Often called a Renaissance Man, Greg is now delivering his most significant contributions to everyone so that future generations will have a planet worth inheriting.",
        url: "https://great4people.com",
        imagePath: "/images/Slide2.png",
        tags: ["#Projects", "#Better World", "#Renaissance Man"],
        category: "Philanthropy"
    },
    {
        title: "The People's Unofficial Presidential Poll",
        description: "Vote for the next President and be counted... Be counted. Vote at The People's Unofficial Presidential Poll. Here you get to decide who you want in charge of the USA.",
        url: "https://ThePeoplesVote.us",
        imagePath: "/images/Slide3.png",
        tags: ["#Vote", "#President", "#Poll", "#USA"],
        category: "Politics"
    },
    {
        title: "What That Means Gifts & Wisdom",
        description: "Learn The Secret Meanings Behind the Media, & Get Epic Life Improving Gifts, Breakthrough Hacks, & Evolutionary Wisdom on Health, Wealth, and Happiness.",
        url: "https://WhatThatMeans.net",
        imagePath: "/images/Slide4.png",
        tags: ["#Gifts", "#Wisdom", "#Media", "#Health", "#Wealth", "#Happiness"],
        category: "Self-Improvement"
    },
    {
        title: "My Solution Maker: Your Place 4 Solving Things",
        description: "Have you been going against the grain for far too long? Here, you will get no nonsense, just ways to solve the challenges. You just need a logical, easy way to succeed.",
        url: "https://mysolutionmaker.com",
        imagePath: "/images/Slide5.png",
        tags: ["#Solutions", "#Challenges", "#Success"],
        category: "Problem Solving"
    },
    {
        title: "Greg Halpern: Inventor of Crowdfunding",
        description: "Greg Halpern invented a trillion-dollar industry in 1998, Crowd Funding, raising $3 million online in one week. Greg has now Invented his big new growth funding.",
        url: "https://inventorofcrowdfunding.com",
        imagePath: "/images/Slide6.png",
        tags: ["#Crowdfunding", "#GrowthFunding", "#Innovation"],
        category: "Innovation"
    },
    {
        title: "Greg Halpern - Battle Tested Winner",
        description: "GREG HALPERN is a battle-tested winner all over the world, having epic personal, professional, physical, and spiritual encounters of every kind and nature.",
        url: "https://battletestedwinner.com",
        imagePath: "/images/Slide7.png",
        tags: ["#Winner", "#Global", "#Encounters"],
        category: "Personal Development"
    },
   
    {
        title: "GREG HALPERN: The Evolving Portal",
        description: "Gregory Halpern's Global Mission is to bring Health, Happiness, Wealth & The Best Life to Everyone. Channel Greg is the evolving portal to launch. All aboard!",
        url: "https://ChannelGreg.com",
        imagePath: "/images/Slide9.png",
        tags: ["#Health", "#Happiness", "#Wealth", "#Global Mission"],
        category: "Wellness"
    },
    {
        title: "Greg Halpern Videos",
        description: "A half-century of Greg Halpern videos in sports, business, news, hobbies, comedy, politics, TV, music, competitions, and lifestyles—it's all video Greg Halpern.",
        url: "https://greghalpernvideo.com",
        imagePath: "/images/Slide10.png",
        tags: ["#Videos", "#Sports", "#Business", "#News", "#Lifestyle"],
        category: "Media"
    },
    {
        title: "MAGA President Trump Saves America",
        description: "Get in on saying THANK YOU PRESIDENT TRUMP FOR SAVING AMERICA. Trump will fix it. Go MAGA.",
        url: "http://thankyoupresidenttrump.us",
        imagePath: "/images/Slide11.png",
        tags: ["#Trump", "#MAGA", "#America"],
        category: "Politics"
    },
    {
        title: "R.A.T. Real Alien Technology & Technofuel",
        description: "Developed over 40 years, R.A.T. embeds into the artificial intelligence mainstream for Positive AI Proliferation. *Does not integrate or use the AsyncRAT loader.",
        url: "https://realalientechnology.com",
        imagePath: "/images/Slide12.png",
        tags: ["#Technology", "#AI", "#Proliferation"],
        category: "Technology"
    },
    {
        title: "Robert David Steele Again",
        description: "Form CIA Spy who crusaded against evil, brought back to life through his crazy interviews with Greg Halpern.",
        url: "https://robert-david-steele-2.com",
        imagePath: "/images/Slide13.png",
        tags: ["#CIA", "#Interviews", "#Crusade"],
        category: "Interviews"
    },
    {
        title: "Formula 4 Protocol by Greg Halpern",
        description: "Hailed by thought leaders and customers as 'The Best Thing You Will Ever Get In Your Life'.",
        url: "https://formula4protocol.teachable.com",
        imagePath: "/images/Slide14.png",
        tags: ["#Protocol", "#Best Thing", "#Life"],
        category: "Self-Improvement"
    },
    {
        title: "Greg Halpern Music",
        description: "Epic Music in Many Genres Produced, Written, Recorded, Performed by and with Greg Halpern.",
        url: "https://GregHalpernMusic.com",
        imagePath: "/images/Slide15.png",
        tags: ["#Music", "#Genres", "#Production"],
        category: "Music"
    },
    {
        title: "Greg Halpern Books",
        description: "Evolutionary Books in Many Genres Produced, Written, and Read by Greg Halpern.",
        url: "https://GregHalpernBooks.com",
        imagePath: "/images/Slide16.png",
        tags: ["#Books", "#Genres", "#Evolutionary"],
        category: "Literature"
    },
    {
        title: "Greg Halpern - Renaissance Man",
        description: "Greg Halpern is a do-gooder for the people, an Author, Filmmaker, martial arts champion, Inventor of Crowdfunding, and survivor of 13 Near-Death Experiences.",
        url: "https://greghalpernlives.com",
        imagePath: "/images/Slide8.png",
        tags: ["#Renaissance Man", "#Author", "#Filmmaker", "#Champion", "#Survivor"],
        category: "Biography"
    }
];

export default data;

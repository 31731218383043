import React, { useState } from 'react';
import data from '../data';

const Categories = ({ onSelectCategory }) => {
  // Extract unique categories from the data and add 'all' to show all items
  const categories = ['All', ...new Set(data.map(item => item.category))];
  const [activeCategory, setActiveCategory] = useState('All');

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    onSelectCategory(category);
  };

  return (
    <nav className="bg-gray-700 fixed left-0 top-0 w-full z-10 rounded-b-lg">
      <ul className="flex flex-wrap justify-center gap-2 p-2 sm:gap-4 sm:p-4 mb-2">
        {categories.map(category => (
          <li key={category} className="list-none">
            <button
              className={`px-2 py-1 sm:px-4 sm:py-2 border border-white rounded-full text-xs sm:text-sm cursor-pointer ${
                activeCategory === category ? 'bg-white text-black' : 'text-white'
              }`}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Categories;
import React, { useState } from 'react';
import Categories from '../components/Categories';
import List from '../components/List';

const Home = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div>
      <div className="sticky top-0 z-10 bg-gray-1100 ">
        <Categories onSelectCategory={handleCategorySelect} />
      </div>
      <List category={selectedCategory} />
    </div>
  );
};

export default Home;

import React from 'react';
import data from '../data';

function List({ category }) {
  const host = "https://greghalpernwiki.com";

  // Filter data based on the selected category, or show all if category is 'all'
  const filteredData = category === 'All' ? data : data.filter(item => item.category === category);

  return (
    <div className="list-container max-w-4xl border border-white rounded-xl h-auto mt-44 md:mt-56 z-0 md:mx-10 mx-8 ">
      {filteredData.map((item, index) => (
        <div
          key={index}
          className={`bg-gray-800 p-2 shadow-md border border-white ${
            filteredData.length === 1 ? 'rounded-xl' : index === 0 ? 'rounded-tl-xl rounded-tr-xl' : index === filteredData.length - 1 ? 'rounded-bl-xl rounded-br-xl' : ''
          }`}
        >
          <div className="list-item-content-container flex flex-col sm:flex-row items-center lg:h-44 md:h-44 ">
            <div className="list-item-image-container w-full sm:w-1/5 flex justify-center mb-2 sm:mb-0">
              <img src={host + item.imagePath} alt={"image"} className="list-item-image w-full h-auto rounded-lg" />
            </div>
            <div className="list-item-tags-container w-full sm:w-1/5 flex flex-wrap justify-center mb-2 sm:mb-0">
              {item.tags.map((tag, tagIndex) => (
                <span key={tagIndex} className="list-item-tag text-blue-500 text-xs font-bold mr-1 mb-1 px-1 py-0.5 rounded">
                  {tag}
                </span>
              ))}
            </div>
            <div className="list-item-content w-full sm:w-2/5 mb-2 sm:mb-0">
              <h2 className="list-item-title text-lg font-bold text-white mb-1">{item.title}</h2>
              <p className="text-gray-300 text-sm">{item.description}</p>
            </div>
            <div className="list-item-button-container w-full sm:w-1/5 flex justify-center">
              <a href={item.url} target="_blank" rel="noopener noreferrer" className="list-item-link text-blue-400 hover:underline text-sm">
                Visit here
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default List;